import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { CookiesProvider } from 'react-cookie'
import theme from '../lib/theme'
import UserContext from '../components/_Elements/UserContext/UserCx'
import { useRef } from 'react';


export default function Baldinini({ Component, pageProps }) {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])
    const scrollRef = useRef({
        scrollPos: 0
    });

    return (
        <>
        
            <Head>
               
                <meta name='viewport' content='initial-scale=1, width=device-width' />
            </Head>
           
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <CookiesProvider>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                    <Component {...pageProps} />
                    </UserContext.Provider>
                </CookiesProvider>
            </ThemeProvider>
            
        </>
    )
}

Baldinini.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired
}
